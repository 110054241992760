import React from "react";

import { StaticImage } from "gatsby-plugin-image";

export type BankLogosProps = {
  logoNumber?: 3 | 4;
};

export function BankLogos({ logoNumber = 3 }: BankLogosProps) {
  if (logoNumber === 4) {
    return (
      <StaticImage
        src="../../../../assets/consumer_brand/Bank_logos_4.png"
        alt="Barclays, HSBC, Natwest and Santander banks logos followed by a plus symbol"
        placeholder="blurred"
        loading="lazy"
        height={20}
      />
    );
  }

  return (
    <StaticImage
      src="../../../../assets/consumer_brand/Banks_logos_checkout.png"
      alt="Barclays, HSBC and Natwest banks logos followed by a plus symbol"
      placeholder="blurred"
      loading="lazy"
      height={20}
    />
  );
}
